import React from 'react';
import './GetPdfBtn.css';
import axios from 'axios';
import FileSaver from 'file-saver';



class GetPdfBtn extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      something:'',
    };
  }


  getPDF(){
    //get starting content
    var content = this.props.appState;

    
      let avgNewStaffPerYear = this.props.appState.outputs.avgNewStaffPerYear;
      avgNewStaffPerYear = parseFloat(avgNewStaffPerYear).toFixed(1);
      let avgHireCostsPerYear = this.props.appState.outputs.avgHireCostsPerYear;
      avgHireCostsPerYear = "£"+numberWithCommas( betterRounding(avgHireCostsPerYear).toFixed(2));
      let trainingInvestmentCost = this.props.appState.outputs.trainingInvestmentCost;
      trainingInvestmentCost = "£"+numberWithCommas( betterRounding(trainingInvestmentCost).toFixed(2));
      let avgSavingImprovedQuality = this.props.appState.outputs.avgSavingImprovedQuality;
      avgSavingImprovedQuality = "£"+numberWithCommas( betterRounding(avgSavingImprovedQuality).toFixed(2));
      let avgSavingImprovedPour = this.props.appState.outputs.avgSavingImprovedPour;
      avgSavingImprovedPour = "£"+numberWithCommas( betterRounding(avgSavingImprovedPour).toFixed(2));
      let avgSavingReducedStaffTurnover = this.props.appState.outputs.avgSavingReducedStaffTurnover;
      avgSavingReducedStaffTurnover = "£"+numberWithCommas( betterRounding(avgSavingReducedStaffTurnover).toFixed(2));
      let avgSavingIncreasedStaffAttendance = this.props.appState.outputs.avgSavingIncreasedStaffAttendance;
      avgSavingIncreasedStaffAttendance = "£"+numberWithCommas( betterRounding(avgSavingIncreasedStaffAttendance).toFixed(2));
      let totalSavings = this.props.appState.outputs.totalSavings;
      totalSavings = "£"+numberWithCommas( betterRounding(totalSavings).toFixed(2));
      let totalROI = this.props.appState.outputs.totalROI;
      totalROI = "£"+numberWithCommas( betterRounding(totalROI).toFixed(2));


    content.pfdValues = {
      avgNewStaffPerYear: avgNewStaffPerYear,
      avgHireCostsPerYear: avgHireCostsPerYear,
      trainingInvestmentCost: trainingInvestmentCost,
      avgSavingImprovedQuality: avgSavingImprovedQuality,
      avgSavingImprovedPour: avgSavingImprovedPour,
      avgSavingReducedStaffTurnover: avgSavingReducedStaffTurnover,
      avgSavingIncreasedStaffAttendance: avgSavingIncreasedStaffAttendance,
      totalSavings: totalSavings,
      totalROI: totalROI,
    };


    const data = {
      content: content
    };
    const options = {
      url: 'https://hellobeer.lcars.space/pdf/index.php',
      method: 'POST',
      /*
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      */
      data: data,
      responseType: 'blob',
    };

    axios(options)
      .then(response => {
        console.log(response);
        var date = new Date().toISOString().substr(0, 10);
        FileSaver.saveAs(response.data, date+'_helloBeer_Results.pdf');
      });
  }

  render() {
    
    return (
      <div className="App__GetPdfBtn">
        <button
        onClick={()=>{this.getPDF()}}
        >
        Download PDF
        </button>
      </div>
      );
  }
}




function betterRounding(number){
  return Math.round(number*100 + Math.sign(number)*0.1**(17-2-(Math.round(number*100)/100).toString().length))/100
}


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}





export default GetPdfBtn;