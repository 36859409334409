import React from 'react';
import './NumberInputField.css';



class NumberInputField extends React.Component {
  constructor(props){
    super(props);
    let startingValue = this.props.value;
    this.state = {
      class:'none',
      hasFocus:false,
      value: startingValue,
    };
  }

  handleChange(event){
    let value = event.target.value;
    this.setState({
      value:value,
    });
    //propage the app state value to use -------------------------------------
    //look for unacceptable values
    //NaN
    if (isNaN(event.target.value)) {
      this.props.updateValue(this.props.name,this.props.default);
      return;
    }
    //blank
    if (value==='' && this.props.notBlankOnBlur) {
      this.props.updateValue(this.props.name,this.props.default);
      return;
    }
    // zero or negitive
    if (value===0 || value==="0" || value<0) {
      this.props.updateValue(this.props.name,this.props.default);
      return;
    }

    //Value is acceptable, handle decimal places
    if (Math.round(this.props.decimalPlaces)===0) {
      value = Math.round(value);
    } else {
      value = parseFloat(value).toFixed(this.props.decimalPlaces);
    }
    this.props.updateValue(this.props.name,value);
  }
  // END handleChange()


  handleFocus(event){
    this.setState({
      class:'focus',
      hasFocus:true,
    });
  }
  handleBlur(event){
    const value = this.props.value;
    this.setState({
      class:'none',
      hasFocus:false,
      value:value,
    }); 
  }

  render() {
    return (
      <div className="App__NumberInputField">
        <input
        className={this.state.class}
        name={this.props.name}
        type="number"
        step={this.props.step}
        onChange={ (event) => {this.handleChange(event)} }
        onFocus={ (event) => {this.handleFocus(event)} }
        onBlur={ (event) => {this.handleBlur(event)} }
        value={this.state.value}
        />
      </div>
      );
  }
}







export default NumberInputField;