import React from 'react';
import './ButtonLink.css';


function ButtonLink(props){

  if (!props.enabled) {
    return null;
  }

  return (
      <div className="App__ButtonLink">
        <button
        onClick={props.onClick}
        >
        {props.text}
        </button>
      </div>
    );
}






export default ButtonLink;