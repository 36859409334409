import React from 'react';
import './InputArea.css';
import NumberInputField from './bricks/NumberInputField.js';
import ButtonPrimary from './bricks/ButtonPrimary.js';
import ButtonLink from './bricks/ButtonLink.js';



class InputArea extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      something:'',
    };
  }


  render() {
      if (!this.props.enabled) {
        return null;
      }
      /*
      let pints = Math.round(this.props.appState.inputs.kegsSoldPerYear * 88);
      pints = numberWithCommas(pints);
      */

      return (
        <div className="App__InputArea"> 
          <h1>Goodbye lost profits, Hello Beer!</h1>
          <p className="intro">Find out what you could save by investing in Hello BEER staff training.</p>
          <p className="intro">Simply input / adjust the values below, then continue to see your results.</p>

          <div className="App__InputArea__Field">
            <label htmlFor="totalBarStaff">Total number of bar staff:</label>
            <NumberInputField
              name="totalBarStaff"
              step="1"
              decimalPlaces="0"
              value={this.props.appState.inputs.totalBarStaff}
              notBlankOnBlur={true}
              default="3"
              updateValue={(field,value)=>this.props.updateInputValue(field,value)}
            />
          </div>

          <div className="App__InputArea__Field">
            <label htmlFor="kegsSoldPerYear">Total number of kegs sold per year:</label>
            <NumberInputField
              name="kegsSoldPerYear"
              step="1"
              decimalPlaces="0"
              value={this.props.appState.inputs.kegsSoldPerYear}
              notBlankOnBlur={true}
              default="500"
              updateValue={(field,value)=>this.props.updateInputValue(field,value)}
            />
          </div>
          <div className="App__InputArea__FieldNote">
            <p>(Based on 50L kegs @ 88 pints per keg)</p>
          </div>

          <div className="App__InputArea__Field">
            <label htmlFor="avgPintCostPrice">Average cost price per pint (£):</label>
            <NumberInputField
              name="avgPintCostPrice"
              step="0.01"
              decimalPlaces="2"
              value={this.props.appState.inputs.avgPintCostPrice}
              notBlankOnBlur={true}
              default="1.40"
              updateValue={(field,value)=>this.props.updateInputValue(field,value)}
            />
          </div>
          <div className="App__InputArea__FieldNote">
            <p>(Price per pint paid to supplier)</p>
          </div>

          <div className="App__InputArea__Field">
            <label htmlFor="ukHospitalityTurnoverPercent">Annual staff turnover (%):</label>
            <NumberInputField
              name="ukHospitalityTurnoverPercent"
              step="1"
              decimalPlaces="0"
              value={this.props.appState.inputs.ukHospitalityTurnoverPercent}
              notBlankOnBlur={true}
              default="30"
              updateValue={(field,value)=>this.props.updateInputValue(field,value)}
            />
          </div>
          <div className="App__InputArea__FieldNote">
            <p>(Average UK Hospitality staff turnover = 30%)</p>
          </div>

          <div className="App__InputArea__Field">
            <label htmlFor="avgCostToHire">Average cost to hire staff (£):</label>
            <NumberInputField
              name="avgCostToHire"
              step="1"
              decimalPlaces="0"
              value={this.props.appState.inputs.avgCostToHire}
              notBlankOnBlur={true}
              default="800"
              updateValue={(field,value)=>this.props.updateInputValue(field,value)}
            />
          </div>
          <div className="App__InputArea__FieldNote">
            <p>(Average UK staff hire cost - &pound;300&nbsp;advertising + &pound;500&nbsp;management &amp; training time = &pound;800)</p>
          </div>

          <div className="App__InputArea__continueBtn"> 
            <ButtonPrimary
            enabled={true}
            text="Continue"
            onClick={()=>this.props.handleContinueClick()}

            />
          </div>

          <div className="App__InputArea__siteLink"> 
            <ButtonLink
            enabled={true}
            text="Back to Hello BEER website"
            onClick={()=>this.props.handleSiteLinkClick()}
            />
          </div>

        </div>
        );
  }
}







function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}








export default InputArea;