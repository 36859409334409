import React from 'react';
import './OutputArea.css';
import NumberInputField from './bricks/NumberInputField.js';
import ButtonPrimary from './bricks/ButtonPrimary.js';
import ButtonBack from './bricks/ButtonBack.js';
import ButtonLink from './bricks/ButtonLink.js';
import GetPdfBtn from './bricks/GetPdfBtn.js';




class OutputArea extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      something:'',
    };
  }


  render() {
      if (!this.props.enabled) {
        return null;
      }
      //get and format outputs
      let avgNewStaffPerYear = this.props.appState.outputs.avgNewStaffPerYear;
      avgNewStaffPerYear = parseFloat(avgNewStaffPerYear).toFixed(1);
      let avgHireCostsPerYear = this.props.appState.outputs.avgHireCostsPerYear;
      avgHireCostsPerYear = "£"+numberWithCommas( betterRounding(avgHireCostsPerYear).toFixed(2));
      let trainingInvestmentCost = this.props.appState.outputs.trainingInvestmentCost;
      trainingInvestmentCost = "£"+numberWithCommas( betterRounding(trainingInvestmentCost).toFixed(2));
      let avgSavingImprovedQuality = this.props.appState.outputs.avgSavingImprovedQuality;
      avgSavingImprovedQuality = "£"+numberWithCommas( betterRounding(avgSavingImprovedQuality).toFixed(2));
      let avgSavingImprovedPour = this.props.appState.outputs.avgSavingImprovedPour;
      avgSavingImprovedPour = "£"+numberWithCommas( betterRounding(avgSavingImprovedPour).toFixed(2));
      let avgSavingReducedStaffTurnover = this.props.appState.outputs.avgSavingReducedStaffTurnover;
      avgSavingReducedStaffTurnover = "£"+numberWithCommas( betterRounding(avgSavingReducedStaffTurnover).toFixed(2));
      let avgSavingIncreasedStaffAttendance = this.props.appState.outputs.avgSavingIncreasedStaffAttendance;
      avgSavingIncreasedStaffAttendance = "£"+numberWithCommas( betterRounding(avgSavingIncreasedStaffAttendance).toFixed(2));
      let totalSavings = this.props.appState.outputs.totalSavings;
      totalSavings = "£"+numberWithCommas( betterRounding(totalSavings).toFixed(2));
      let totalROI = this.props.appState.outputs.totalROI;
      totalROI = "£"+numberWithCommas( betterRounding(totalROI).toFixed(2));

      return (
        <div className="App__OutputArea">


          <h1>Your Results</h1>

          <table>
          <tbody>
            <tr>
              <th>Hello BEER staff training investment<br/><span>(£40 per licence, each licence covers up to 20 staff)</span></th>
              <td>{trainingInvestmentCost}</td>
            </tr>
            </tbody>
          </table>
          <div className="App__OutputArea__ResultNote">
            <p>(Hello BEER is free via HEINEKEN Buying Club)</p>
          </div>

          <table className="yellow">
          <tbody>
            <tr>
              <th>Average saving: improved quality<sup>1</sup></th>
              <td>{avgSavingImprovedQuality}</td>
            </tr>
            <tr>
              <th>Average saving: improved pour<sup>1</sup></th>
              <td>{avgSavingImprovedPour}</td>
            </tr>
            <tr>
              <th>6% reduction in staff turnover<sup>2</sup></th>
              <td>{avgSavingReducedStaffTurnover}</td>
            </tr>
            <tr>
              <th>6% increase in staff attendance<sup>2</sup></th>
              <td>{avgSavingIncreasedStaffAttendance}</td>
            </tr>
            </tbody>
          </table>

          <table className="space">
          <tbody>
            <tr className="totalReturn" >
              <th>Predicted Return Value</th>
              <td>{totalSavings}</td>
            </tr>
            </tbody>
          </table>
          
          <table className="space">
          <tbody>
            <tr className="totalROI" >
              <th>Total ROI</th>
              <td>{totalROI}</td>
            </tr>
            </tbody>
          </table>



          <div className="App__OutputArea__Sources">
            <h6>Sources</h6>
            <p><sup>1</sup> Vianet Beer Insights Report 2019/20. 3% improved quality, 1% improved pour</p>
            <p><sup>2</sup> Get Smarter Employee Training & Development: The Benefits and Why It’s Important, Jan 24 2019. 94% would remain at a company that invests in their development.</p>
          </div>



          <div className="App__OutputArea__pdfBtn">
            <GetPdfBtn
            appState={this.props.appState}
            />
          </div>

          
          
          <div className="App__OutputArea__backBtn"> 
            <ButtonBack
            enabled={true}
            text="< Back to input"
            onClick={()=>this.props.handleBackClick()}
            />
          </div>
          
          
          <div className="App__OutputArea__siteLink"> 
            <ButtonLink
            enabled={true}
            text="Back to Hello BEER website"
            onClick={()=>this.props.handleSiteLinkClick()}
            />
          </div>


        </div>
        );
  }
}



function betterRounding(number){
  return Math.round(number*100 + Math.sign(number)*0.1**(17-2-(Math.round(number*100)/100).toString().length))/100
}


function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}











export default OutputArea;