import React from 'react';
import './Header.css';
import logo from '../img/hello-beer-logo.svg';




class Header extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      something:'',
    };



  }
  handleClick(){
  }

  render() {
    return (
      <div className="App__Header">
        <header>
          <div className="logo">
            <img src={logo} alt="" width="172" height="42" onClick={()=>this.props.handleLogoClick()} />
          </div>
        </header>
      </div>
      );
  }
}

export default Header;