import React from 'react';
import './App.css';

import Header from './parts/Header.js';
import InputArea from './parts/InputArea.js';
import OutputArea from './parts/OutputArea.js';


class App extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      view:'input',
      inputs:{
        totalBarStaff:3,
        kegsSoldPerYear:500,
        avgPintCostPrice:"1.40",
        ukHospitalityTurnoverPercent:30,
        avgCostToHire:800,
      },
      outputs:{
        avgNewStaffPerYear:0,
        avgHireCostsPerYear:0,
        trainingInvestmentCost:0,
        avgSavingImprovedQuality:0,
        avgSavingImprovedPour:0,
        avgSavingReducedStaffTurnover:0,
        avgSavingIncreasedStaffAttendance:0,
        totalSavings:0,
        totalROI:0,
      },

    };
  }
  // END constructor()

  updateOutputsFromInputs(){
    let avgNewStaffPerYear = 0;
    let avgHireCostsPerYear = 0;
    let trainingInvestmentCost = 0;
    let avgSavingImprovedQuality = 0;
    let avgSavingImprovedPour = 0;
    let avgSavingReducedStaffTurnover = 0;
    let avgSavingIncreasedStaffAttendance = 0;
    let totalSavings = 0;
    let totalROI = 0;

    // calc avgNewStaffPerYear
    avgNewStaffPerYear = this.state.inputs.totalBarStaff
                         * (this.state.inputs.ukHospitalityTurnoverPercent / 100);

    // calc avgHireCostsPerYear
    avgHireCostsPerYear = avgNewStaffPerYear
                          * this.state.inputs.avgCostToHire;

    // calc trainingInvestmentCost (£40 per 20 staff)
    trainingInvestmentCost = 40 * (Math.trunc((this.state.inputs.totalBarStaff-1)/20) + 1);

    // calc avgSavingImprovedQuality
    avgSavingImprovedQuality = this.state.inputs.avgPintCostPrice
                               * this.state.inputs.kegsSoldPerYear
                               * 88 //88 pints in a keg
                               * 0.03; //3% saving

    // calc avgSavingImprovedPour
    // avgSavingImprovedQuality divided by 3 to give the 1%
    avgSavingImprovedPour = avgSavingImprovedQuality/3;

    // calc avgSavingReducedStaffTurnover
    avgSavingReducedStaffTurnover = 0.02
                                    * this.state.inputs.totalBarStaff
                                    * this.state.inputs.avgCostToHire;
    //above seems incorrect, so chagned to the below
    avgSavingReducedStaffTurnover = 0.06 * avgHireCostsPerYear;

    // calc avgSavingIncreasedStaffAttendance
    avgSavingIncreasedStaffAttendance = 7.5 * (0.06 * this.state.inputs.totalBarStaff * (7*7.5) );

    // calc totalSavings
    totalSavings = avgSavingImprovedQuality
                   + avgSavingImprovedPour
                   + avgSavingReducedStaffTurnover
                   + avgSavingIncreasedStaffAttendance;

    // calc totalROI
    totalROI = totalSavings - trainingInvestmentCost;

    // update state
    this.setState({
      outputs:{
        avgNewStaffPerYear:avgNewStaffPerYear,
        avgHireCostsPerYear:avgHireCostsPerYear,
        trainingInvestmentCost:trainingInvestmentCost,
        avgSavingImprovedQuality:avgSavingImprovedQuality,
        avgSavingImprovedPour:avgSavingImprovedPour,
        avgSavingReducedStaffTurnover:avgSavingReducedStaffTurnover,
        avgSavingIncreasedStaffAttendance:avgSavingIncreasedStaffAttendance,
        totalSavings:totalSavings,
        totalROI:totalROI,
      },
    });
  }
  // END updateOutputsFromInputs()

  handleInputValueChange(field,value){
    const inputs = this.state.inputs;
    inputs[field] = value;
    this.setState({
      inputs:inputs,
    });
  }
  // END handleInputValueChange()

  handleContinueClick(){
    this.updateOutputsFromInputs();
    this.setState({
      view: 'output',
    });
  }
  // END handleContinueClick()

  handleBackClick(){
    this.setState({
      view: 'input',
    });
  }
  // END handleBackClick()

  handleSiteLinkClick(){
    window.location.href = 'https://hellobeerapp.com/';
  }
  // END handleSiteLinkClick()

  handlePdfClick(){
    return;
  }
  // END handlePdfClick()

  render() {
    return (
      <div className="App">
        <Header 
          handleLogoClick={()=>this.handleSiteLinkClick()}
        />
        <div className="App__Body">

          <InputArea
            enabled={this.state.view==='input'}
            appState={this.state}
            updateInputValue={(field,value)=>this.handleInputValueChange(field,value)}
            handleContinueClick={()=>this.handleContinueClick()}
            handleSiteLinkClick={()=>this.handleSiteLinkClick()}
           />
          <OutputArea
            enabled={this.state.view==='output'}
            appState={this.state}
            handlePdfClick={()=>this.handlePdfClick()}
            handleBackClick={()=>this.handleBackClick()}
            handleSiteLinkClick={()=>this.handleSiteLinkClick()}
           />

        </div>
      </div>
      );
  }
  // END render()
}


export default App;
