import React from 'react';
import './ButtonPrimary.css';


function ButtonPrimary(props){

  if (!props.enabled) {
    return null;
  }

  return (
      <div className="App__ButtonPrimary">
        <button
        onClick={props.onClick}
        >
        {props.text}
        </button>
      </div>
    );
}






export default ButtonPrimary;